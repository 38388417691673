import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { isEmpty } from '@/lib/helper'
import { markdownRenderOptions } from '@/constants/markdownRenderOptions'

const Promobar = (props) => {
  const messageText = props?.message?.content[0]?.content[0]?.value || ''

  return (
    <>
      {messageText != '' && (
        <div className="promobar">
          {props.show ? (
            <div
              className="promobar__message flex items-center justify-center w-full h-full"
              style={{
                backgroundColor: `#${props.backgroundColor}`,
                color: `#${isEmpty(props.textColor) ? '#ffffff' : props.textColor}`,
              }}
            >
              {documentToReactComponents(props.message, markdownRenderOptions)}
            </div>
          ) : null}
        </div>
      )}
    </>
  )
}

export default Promobar
