import { useState, useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import remarkGfm from 'remark-gfm'
import InnerHTML from 'dangerously-set-html-content'

import { enableMarkdownTextPreview } from '@/lib/contentful/module'

const Markdown = (props) => {
  const { preview } = props
  const [item, setItem] = useState(preview ? props : props?.children || props?.fields?.markdownText)

  const withScript = (inputString) => {
    const pattern = /<script\b[^>]*>[\s\S]*?<\/script>/i

    return pattern.test(inputString)
  }

  useEffect(async () => {
    if (preview) {
      const res = await enableMarkdownTextPreview(props, preview)
      setItem(res)
    }
  }, [])

  return (
    <div className="module-markdown container prose">
      {withScript(item) ? (
        <InnerHTML html={item} />
      ) : (
        <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
          {item}
        </ReactMarkdown>
      )}
      <style>
        {`
          .prose > * {
            text-align: inherit;
          }

          .list li::marker {
            color: #111111;
          }

          .prose a {
            display: unset;
          }
      `}
      </style>
    </div>
  )
}

export default Markdown
